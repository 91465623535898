import { Group } from '@/api/GroupApi';
import { PollsterLoginAttempt } from '@/api/PollsterApi';
import { Attachment } from './types';
import { PreQuestionnaireTempalte } from '@/api/PreQuestionnaireApi';

export enum Role {
	ADMIN = 'ROLE_ADMIN',
	POLLSTER = 'ROLE_POLLSTER',
	PANELIST = 'ROLE_PANELIST',
	CLIENT = 'ROLE_CLIENT',
}

export enum UserStatus {
	INACTIVE,
	ACTIVE,
	SUSPENDED,
}

export interface Address {
	postalCode?: string;
	city?: string;
}

export interface BaseUser {
	id: string;
	email: string;
	phone: string;
	firstName?: string;
	lastName?: string;
	password?: string;
	status: UserStatus;
	createdAt?: string;
}

export interface Admin extends BaseUser {
	createdAt?: string;
	role: Role.ADMIN;
	group: Group | string;
	avatar?: string | File | null | Attachment;
}

export interface Client extends BaseUser, Address {
	address?: string;
	vatNumber: string;
	companyName: string;
	role: Role.CLIENT;
	priceModifier: number;
	excludedModules: PreQuestionnaireTempalte[];
	excludeFromInvoices: boolean;
}

export interface Panelist extends BaseUser, Address {
	balance: number;
	totalPayouts?: number;
	accountNumber?: string;
	role: Role.PANELIST;
	preQuestionnaireProgress?: number;
	defaultQuestionnaireProgress?: number;
	county?: string;
	province?: string;
	referralCode: string;
	points: number;
	birthDate: string;
	userIp: string;
	extraPoints: number;
	pauseQuestionnaires: boolean;
	lastActiveAt?: string;
	notificationsEnabled: boolean;
	affiliationsCount: number;
}

export interface Pollster extends BaseUser {
	station: number;
	currentLoginAttempt?: PollsterLoginAttempt;
	role: Role.POLLSTER;
}

export type User = Admin;
