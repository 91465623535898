import { ResearchStatus } from '@/types/research';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	HEADER_HEIGHT: 70,
	RESEARCH_GROUP_INITIAL_AMOUNT: 1000,
	RESEARCH_GROUP_MIN_LOI: 1,
	RESEARCH_GROUP_MAX_LOI: 50,
	RESEARCH_GROUP_MIN_DURATION: 1,
	RESEARCH_GROUP_MAX_DURATION: 365,
	RESEARCH_GROUP_MIN_AMOUNT: 10,
	RESEARCH_GROUP_MAX_AMOUNT: 200_000,
	RESEARCH_MAX_CROSSINGS: 25_000,
	RESEARCH_MAX_FEASIBILITY: 3000,
	RESEARCH_GENERATE_CROSSING_DEBOUNCE: 2000,
	RESEARCH_PENDING_STATUSES: [
		ResearchStatus.SOFT_LAUNCH,
		ResearchStatus.FULL_LAUNCH,
	] as ResearchStatus[],
	RESEARCH_EDITABLE_STATUSES: [
		ResearchStatus.DRAFT,
		ResearchStatus.TESTING,
		ResearchStatus.ACCEPTED,
		ResearchStatus.SOFT_LAUNCH,
		ResearchStatus.FULL_LAUNCH,
	] as ResearchStatus[],
	MIN_AGE: 18,
	MAX_AGE: 99,
	TABLE_PAGE_SIZES: [15, 50, 100],
	REFERRAL_CODE_LENGTH: {
		MIN: 5,
		MAX: 20,
	},
	DEFAULT_LANGUAGE: 'PL',
	APP_LAUNCH_DATE: new Date('2023-01-01'),
	GENDER_QUESITON_ID: '1ed08d70-8bcd-6136-8e99-b7834744ef9f',
} as const;

export default APP_CONFIG;
