import { QueryKey } from '@/configs/queryKeys';
import { ForeignLanguage } from '@/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import httpClient from './clients/httpClient';

export enum MailTemplateType {
	RESET_PASSWORD = 1,
	PASSWORD_CHANGED = 2,
	ADMIN_NEW_RESEARCH = 10,
	ADMIN_RESEARCH_ENDED = 11,
	ADMIN_NEW_CLIENT = 12,
	PANELIST_ACTIVE = 20,
	PANELIST_REGISTER = 21,
	PANELIST_PREQUESTIONNAIRE_REMINDER = 22,
	PANELIST_PAYOUT_REQUEST = 23,
	PANELIST_ACTIVITY_REMINDER = 24,
	PANELIST_NEW_QUESTIONNAIRE = 25,
	PANELIST_WARNING = 26,
	PANELIST_SUSPENSION = 27,
	PANELIST_BAN = 28,
	PANELIST_QUESTIONNAIRE_REMINDER = 29,
	CLIENT_REGISTER = 30,
	RESEARCH_STARTED = 31,
	CLIENT_INVOICE = 32,
	RESEARCH_ENDED = 33,
	CLIENT_RESEARCH_SUMMARY = 34,
	CLIENT_NEW_RESEARCH = 35,
	CLIENT_ACTIVATED = 36,
	CLIENT_SOFT_LAUNCH_ENDED = 37,
	NEW_TARGET_GROUP = 38,
	PANELIST_INACTIVITY_WARNING = 201,
	PANELIST_INACTIVITY_DELETION = 202,
	FEEDBACK_RESPONSE = 203,
}

export interface MailTemplate {
	id: string;
	subject: string;
	HTMLTemplate: string;
	availableVariables: Record<string, string>;
	mailType: MailTemplateType;
	translations: Record<
		ForeignLanguage,
		{
			subject: string;
			HTMLTemplate: string;
		}
	>;
}

abstract class MailTemplateApi {
	static getAll = async ({ queryKey }: QueryFunctionContext<[QueryKey]>) => {
		const response = await httpClient.get<MailTemplate[]>(queryKey[0], {
			params: {
				pagination: false,
			},
		});

		return response.data;
	};

	static update = async (mailTemplate: Partial<MailTemplate>) => {
		const response = await httpClient.put<MailTemplate>(
			`${QueryKey.mailTemplates}/${mailTemplate.id}`,
			mailTemplate
		);

		return response.data;
	};
}

export default MailTemplateApi;
