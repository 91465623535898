import { ReactNode } from 'react';
import {
	MantineProvider as Mantine,
	MantineThemeOverride,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { SpinnerLoader } from '@/components/ui/Loading';
import { DatesProvider } from '@mantine/dates';
import mantineStyles from '@/assets/scss/custom/mantine.module.scss';
import classNames from 'classnames';
import 'dayjs/locale/pl';

const colors: MantineThemeOverride['colors'] = {
	blue: [
		'#eff6ff',
		'#dbeafe',
		'#bfdbfe',
		'#c3d5f8',
		'#60a5fa',
		'#3b82f6',
		'#2563eb',
		'#1d4ed8',
		'#1e5fa7',
		'#2e417f',
	],
};

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	const components: MantineThemeOverride['components'] = {
		Tooltip: {
			defaultProps: {
				withArrow: true,
				multiline: true,
			},
			classNames: {
				tooltip: classNames('tooltip', mantineStyles.tooltipTooltip),
			},
		},
		Select: {
			classNames: {
				input: mantineStyles.selectInput,
				item: mantineStyles.selectItem,
				rightSection: mantineStyles.selectRightSection,
				nothingFound: mantineStyles.selectNothingFound,
			},
			defaultProps: {
				rightSectionWidth: 30,
			},
		},
		MultiSelect: {
			classNames: {
				searchInput: mantineStyles.selectSearchInput,
				input: mantineStyles.selectInput,
				item: mantineStyles.selectItem,
				rightSection: mantineStyles.selectRightSection,
				nothingFound: mantineStyles.selectNothingFound,
			},
			defaultProps: {
				rightSectionWidth: 30,
			},
		},
		LoadingOverlay: {
			defaultProps: {
				loader: <SpinnerLoader />,
			},
		},
		Slider: {
			classNames: {
				markLabel: mantineStyles.sliderMarkLabel,
			},
			defaultProps: {
				color: 'blue.6',
				labelTransition: 'fade',
				labelTransitionDuration: 250,
			},
		},
		RangeSlider: {
			defaultProps: {
				color: 'blue.6',
				labelTransition: 'fade',
				minRange: 1,
				labelTransitionDuration: 250,
			},
		},
		Avatar: {
			classNames: {
				placeholder: mantineStyles.avatarPlaceholder,
			},
		},
		Popover: {
			defaultProps: {
				position: 'bottom-end',
				shadow: 'md',
				radius: '.4rem',
			},
			classNames: {
				dropdown: `${mantineStyles.popoverDropdown} popover`,
			},
		},
		Pagination: {
			classNames: {
				control: mantineStyles.paginationControl,
				dots: mantineStyles.paginationDots,
			},
			defaultProps: {
				spacing: 0,
			},
		},
		MonthPicker: {
			defaultProps: {
				withCellSpacing: false,
				size: 'xs',
			},
			classNames: {
				calendarHeader: mantineStyles.monthPickerHeader,
				monthsListCell: mantineStyles.monthPickerMonthsListCell,
			},
		},
		DatePickerInput: {
			defaultProps: {},
			classNames: {
				input: 'form-control',
				calendar: mantineStyles.datePickerCalendar,
				monthCell: mantineStyles.datePickerMonthCell,
			},
		},
		DateTimePicker: {
			defaultProps: {},
			classNames: {
				input: 'form-control',
				timeInput: classNames(
					mantineStyles.datePickerTimeInput,
					'form-control'
				),
				timeWrapper: mantineStyles.datePickerTimeWrapper,
				calendar: mantineStyles.datePickerCalendar,
				monthCell: mantineStyles.datePickerMonthCell,
			},
		},
		Switch: {
			defaultProps: {
				size: 'md',
				color: 'blue.6',
			},
			classNames: {
				track: mantineStyles.switchTrack,
				body: mantineStyles.switchBody,
				label: classNames('form-check-label', mantineStyles.switchLabel),
			},
		},
		Spoiler: {
			defaultProps: {},
			classNames: {
				control: mantineStyles.spoilerControl,
			},
		},
	};

	return (
		<Mantine
			theme={{
				fontFamily: 'Poppins, sans-serif',
				components,
				colors,
			}}
		>
			<ModalsProvider>
				<DatesProvider settings={{ locale: 'pl' }}>{children}</DatesProvider>
			</ModalsProvider>
		</Mantine>
	);
};
