import { createRoot } from 'react-dom/client';
import { App } from './App';
import { AppProviders } from '@/providers/AppProviders';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './assets/scss/theme.scss';

if (import.meta.env.PROD) {
	disableReactDevTools();
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
