import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AnswerTemplate,
	PreQuestionnaireTempalte,
	QuestionTemplate,
	QuestionType,
} from '@/api/PreQuestionnaireApi';
import { Language } from '@/types/types';
import { StoreKeys } from '@/configs/StoreKeys';

export type ReorderDirection = 'up' | 'down';

const initialState: PreQuestionnaireTempalte = {
	id: 'newTemplate',
	name: '',
	reward: 10,
	customOrder: 0,
	isRequired: false,
	isDefault: false,
	color: '#ffffff',
	translations: {
		PL: {
			name: '',
		},
		GB: {
			name: '',
		},
		DE: {
			name: '',
		},
	},
	questionTemplates: [returnBlankQuestion(0)],
};

export const questionnaireTemplateSlice = createSlice({
	name: StoreKeys.questionnaireTemplate,
	initialState,
	reducers: {
		loadQuestionnaire: (
			state,
			{ payload }: PayloadAction<PreQuestionnaireTempalte>
		) => {
			const loadedTemplate = payload;
			loadedTemplate.questionTemplates = loadedTemplate.questionTemplates
				.filter((question) => !question.relatedAnswerTemplate)
				.sort((a, b) => (a.questionOrder > b.questionOrder ? 1 : -1));

			Object.assign(state, payload);
		},
		resetQuestionnaire: (state) => {
			Object.assign(state, initialState);
		},
		editQuestionnaire: (
			state,
			{ payload }: PayloadAction<Partial<PreQuestionnaireTempalte>>
		) => {
			Object.assign(state, payload);
		},
		addQuestion: (state, { payload }: PayloadAction<string | undefined>) => {
			if (!payload) {
				state.questionTemplates.push(
					returnBlankQuestion(state.questionTemplates.length)
				);
				return;
			}

			const answer = findAnswer(state.questionTemplates, payload);
			if (!answer) return;

			answer.relatedQuestionTemplates.push(
				returnBlankQuestion(answer.relatedQuestionTemplates.length)
			);
		},
		removeQuestion: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionId: string;
				answerId?: string;
			}>
		) => {
			if (!payload.answerId) {
				state.questionTemplates = state.questionTemplates.filter(
					(question) => question.id !== payload.questionId
				);
				state.questionTemplates.forEach((question, idx) => {
					question.questionOrder = idx;
				});

				return;
			}

			const answer = findAnswer(state.questionTemplates, payload.answerId);
			if (!answer) return;

			answer.relatedQuestionTemplates = answer.relatedQuestionTemplates.filter(
				(question) => question.id !== payload.questionId
			);
			answer.relatedQuestionTemplates.forEach((answer, idx) => {
				answer.questionOrder = idx;
			});
		},
		changeQuestion: (
			state,
			{ payload }: PayloadAction<Partial<QuestionTemplate>>
		) => {
			const question = findQuestion(state.questionTemplates, payload.id!);
			if (!question) return;

			Object.assign(question, payload);
		},
		moveQuestion: (
			state,
			{
				payload,
			}: PayloadAction<{
				currentOrder: number;
				direction: ReorderDirection;
				answerId?: string;
			}>
		) => {
			const fromIndex = payload.currentOrder;
			const toIndex =
				payload.direction === 'up' ? fromIndex - 1 : fromIndex + 1;

			if (!payload.answerId) {
				[state.questionTemplates[fromIndex], state.questionTemplates[toIndex]] =
					[
						state.questionTemplates[toIndex],
						state.questionTemplates[fromIndex],
					];

				state.questionTemplates.forEach((question, idx) => {
					question.questionOrder = idx;
				});

				return;
			}

			const answer = findAnswer(state.questionTemplates, payload.answerId);
			if (!answer) return;

			[
				answer.relatedQuestionTemplates[fromIndex],
				answer.relatedQuestionTemplates[toIndex],
			] = [
				answer.relatedQuestionTemplates[toIndex],
				answer.relatedQuestionTemplates[fromIndex],
			];

			answer.relatedQuestionTemplates.forEach((question, idx) => {
				question.questionOrder = idx;
			});
		},
		addAnswer: (state, { payload }: PayloadAction<string>) => {
			const question = findQuestion(state.questionTemplates, payload);
			if (!question) return;

			question.answerTemplates.push(
				returnBlankAnswer(
					question.id,
					crypto.randomUUID(),
					question.answerTemplates.length
				)
			);
		},
		addMultipleAnswers: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionId: string;
				answers: string[];
				language: Language;
			}>
		) => {
			const question = findQuestion(
				state.questionTemplates,
				payload.questionId
			);
			if (!question) return;

			let answerOrder = question.answerTemplates.length;
			for (const answer of payload.answers) {
				question.answerTemplates.push(
					returnBlankAnswer(
						question.id,
						crypto.randomUUID(),
						answerOrder,
						answer,
						payload.language
					)
				);
				answerOrder++;
			}
		},
		removeAnswer: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionId: string;
				answerId?: string;
			}>
		) => {
			const question = findQuestion(
				state.questionTemplates,
				payload.questionId
			);
			if (!question) return;

			question.answerTemplates = question.answerTemplates.filter(
				(question) => question.id !== payload.answerId
			);
			question.answerTemplates.forEach((answer, idx) => {
				answer.answerOrder = idx;
			});
		},
		changeAnswer: (
			state,
			{ payload }: PayloadAction<Partial<AnswerTemplate>>
		) => {
			const answer = findAnswer(state.questionTemplates, payload.id!);
			if (!answer) return;

			Object.assign(answer, payload);
		},
		moveAnswer: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionId: string;
				currentOrder: number;
				direction: ReorderDirection;
			}>
		) => {
			const question = findQuestion(
				state.questionTemplates,
				payload.questionId
			);

			if (!question) return;

			const fromIndex = payload.currentOrder;
			const toIndex =
				payload.direction === 'up' ? fromIndex - 1 : fromIndex + 1;

			[question.answerTemplates[fromIndex], question.answerTemplates[toIndex]] =
				[
					question.answerTemplates[toIndex],
					question.answerTemplates[fromIndex],
				];

			question.answerTemplates.forEach((answer, idx) => {
				answer.answerOrder = idx;
			});
		},
	},
});

export const {
	loadQuestionnaire,
	resetQuestionnaire,
	editQuestionnaire,
	addQuestion,
	removeQuestion,
	changeQuestion,
	moveQuestion,
	addAnswer,
	addMultipleAnswers,
	removeAnswer,
	changeAnswer,
	moveAnswer,
} = questionnaireTemplateSlice.actions;

export default questionnaireTemplateSlice.reducer;

export function returnBlankQuestion(questionOrder: number): QuestionTemplate {
	const newQId = crypto.randomUUID();
	return {
		id: newQId,
		preQuestionnaireModuleTemplate: 'newTemplate',
		content: '',
		description: '',
		type: QuestionType.MULTISELECT,
		questionOrder,
		isNew: true,
		answerTemplates: [returnBlankAnswer(newQId, crypto.randomUUID(), 0)],
		translations: {
			PL: { content: '', description: '' },
			GB: { content: '', description: '' },
			DE: { content: '', description: '' },
		},
	};
}

function returnBlankAnswer(
	questionId: string,
	answerId: string,
	answerOrder: number,
	content = '',
	language?: Language
): AnswerTemplate {
	const answer = {
		id: answerId,
		questionTemplate: questionId,
		answerOrder,
		content,
		isNew: true,
		relatedQuestionTemplates: [],
		translations: {
			PL: { content: '' },
			GB: { content: '' },
			DE: { content: '' },
		},
	};
	if (language) answer.translations[language].content = content;

	return answer;
}

function findAnswer(
	questions: QuestionTemplate[],
	answerId: string
): AnswerTemplate | undefined {
	for (const question of questions) {
		for (const answer of question.answerTemplates) {
			if (answer.id === answerId) return answer;
		}
	}

	for (const question of questions) {
		for (const answer of question.answerTemplates) {
			if (!answer.relatedQuestionTemplates.length) continue;

			const a = findAnswer(answer.relatedQuestionTemplates, answerId);
			if (a) return a;
		}
	}
}

function findQuestion(
	questions: QuestionTemplate[],
	questionId: string
): QuestionTemplate | undefined {
	for (const question of questions) {
		if (question.id === questionId) return question;
	}

	for (const question of questions) {
		for (const answer of question.answerTemplates) {
			if (!answer.relatedQuestionTemplates.length) continue;

			const q = findQuestion(answer.relatedQuestionTemplates, questionId);
			if (q) return q;
		}
	}
}
