import { QuestionnaireStatus } from '@/api/PanelistApi';
import { AnswerTemplate } from '@/api/PreQuestionnaireApi';
import { Region } from '@/api/RegionsApi';
import { Attachment, Gender, Language, NotificationMessageType } from './types';
import { Admin, Client } from './user';

export interface Research {
	id: string;
	fullService: boolean;
	sendToAppOnly: boolean;
	name: string;
	description: string;
	client?: Client | null;
	researchGroups: ResearchGroup[];
	researchAttachments: ResearchAttachment[];
	researchToken: GeneratedLinks;
	contactPersons: ContactPerson[];
	screenoutPoints?: number;
	quotafullPoints?: number;
	completedPoints?: number;
	qualPoints?: number;
	completedAmount?: number;
	status: ResearchStatus;
	sentAmount?: number;
	createdAt: string;
	startsAt?: string;
	startedAt?: string;
	finishedAt?: string;
}

export interface ResearchGroup {
	id: string;
	show: boolean;
	name: string;
	isNew: boolean;
	isRepresentativeMode: boolean;
	isInvitedsLoaded: boolean;
	mobile: boolean;
	desktop: boolean;
	cameraRequired: boolean;
	dataCollecting: boolean;
	isBooster: false;
	amountOfQuestionnaires: number;
	gender: Gender;
	regionSwitch: LocalizationTypes;
	regionsChosen: Region[];
	postalCodesChosen: string[];
	ageSpan: [number, number];
	startsAt: string;
	finishedAt?: string;
	duration: number;
	loi: number;
	ir: number;
	profilesChosen: ProfileChosen[];
	questionsChosen: Question[];
	quantity: {
		gender: {
			isAuto: boolean;
			male: number;
			female: number;
		};
		region: {
			isAuto: boolean;
			regions: { id: string; region: Region; value: number }[];
			postalCodes: { code: string; value: number }[];
		};
		age: {
			isAuto: boolean;
			spans: AgeSpan[];
		};
	};
	invitedPanelists: string[];
	excludedPanelists: string[];
	note: string;
	questionnaireUrl: string;
	testQuestionnaireUrl: string;
	crossing: Cross[];
	userCrosses: UserCross[];
	crossSwitches: CrossSwitches;
	omitId: boolean;
	isUniqueLinks: boolean;
	uniqueLinks: string[];
}

export enum ResearchStatus {
	DRAFT,
	ACCEPTED,
	TESTING,
	SOFT_LAUNCH,
	FULL_LAUNCH,
	FINISHED,
}

export interface Question {
	id: string;
	answers: Answer[];
	translations: {
		[key in Language]: { content: string; description: string };
	};
}

export interface Answer {
	id: string;
	value: number;
	translations: {
		[key in Language]: { content: string };
	};
}

export interface ProfileChosen {
	id: string;
	isAuto: boolean;
	name: string;
	questions: Question[];
	translations: {
		[key in Language]: { name: string };
	};
}

export interface Cross {
	id?: string;
	ageFrom: number;
	ageTo: number;
	gender: Gender;
	answers: { id: string; translations: AnswerTemplate['translations'] }[];
	amount: number;
	province: string | null;
	county: string | null;
	city: string | null;
	postalCode: string | null;
	feasibility?: number;
}

export type UserCross = Omit<Cross, 'feasibility'>;

export interface AgeSpan {
	id: string;
	span: [number, number];
	value: number;
}

export interface CrossSwitches {
	gender: boolean;
	age: boolean;
	[key: string]: boolean;
}

export enum LocalizationTypes {
	COUNTRY = 1,
	REGION = 2,
	POSTAL_CODE = 3,
}

export interface Feasibility {
	ageFrom: number;
	ageTo: number;
	gender: Gender;
	answers: { id: string; content: string }[];
	postalCode?: string;
	city?: string;
	province?: string;
	county?: string;
	feasibility: number;
}

export interface ContactPerson {
	name: string;
	surname: string;
	email: string;
	phone: string;
}

export interface ResearchAttachment {
	id: string;
	attachment: Attachment;
	research: Research;
	createdAt: string;
	updatedAt: string;
}

export interface ListResearch
	extends Pick<
		Research,
		'id' | 'name' | 'client' | 'status' | 'completedAmount' | 'startedAt'
	> {
	researchGroups: Pick<ResearchGroup, 'amountOfQuestionnaires'>[];
	lastStatusHistory?: ResearchHistoryLog;
}

export interface CalculatedPrice {
	finalPrice: number;
	singlePrice: number;
	discount?: number;
}

export interface GeneratedLinks {
	id: string;
	completed: string;
	screenOut: string;
	quotaFull: string;
	qual: string;
}

export interface GroupStats {
	group: string;
	group_id: string;
	totalCount: number;
	started: number;
	finished: number;
	completed: number;
	quotafull: number;
	screenout: number;
	dropout: number;
	timeout: number;
	qual: number;
	cpi: number;
	initialCpi: number;
	loi_sum: number;
	declared_loi: number;
	real_ir: number;
	declared_ir: number;
}

export interface CrossStat {
	id: string;
	completed: number;
	declared: number;
	fulfilled: number;
	quotafull: number;
	screenout: number;
	qual: number;
	sent: number;
	title: string;
}

export interface ResearchGroupPointsStats {
	screenout: {
		points: number;
		money: number;
		profit: number;
	};
	completed: {
		points: number;
		money: number;
		profit: number;
	};
	quotafull: {
		points: number;
		money: number;
		profit: number;
	};
	qual: {
		points: number;
		money: number;
		profit: number;
	};
}

export interface ResearchGroupStatsUniqueUrl {
	link: string;
	panelistId: string;
	status: 'available' | 'used';
}

export interface ResearchGroupUrlStats {
	target: string;
	test_target: string;
	note: string;
	isUniqueLinks: boolean;
	uniqueLinks: ResearchGroupStatsUniqueUrl[];
}

export interface ResearchStats {
	questionnaireStats: {
		mostRecentQuestionnaire?: {
			date: string;
		};
		amountOfStartedQuestionnaires: number;
		clickRate: number;
		responseRate: number;
		availableToNotify: number;
		totals: {
			completed: number;
			finished: number;
			quotafull: number;
			screenout: number;
			qual: number;
			total: number;
		};
	};
	groupsStats: GroupStats[];
	crossStats: {
		id: string;
		name: string;
		crossings: CrossStat[];
	}[];
	invitedStats: {
		id: string;
		name: string;
		data: { panelist: string; result: QuestionnaireStatus }[];
	}[];
	urls: Record<string, ResearchGroupUrlStats>;
	finance: {
		groups: {
			group: string;
			cpi: number;
			cptg: number;
			initialCpi: number;
			initialCptg: number;
		}[];
		research: { cptg: number }[];
	};
	contactPersons: ContactPerson[];
	dates: {
		startedAt: { date: string };
		approximatedFinishDate: { date: string };
	};
	points: {
		[groupId: string]: ResearchGroupPointsStats;
	};
}

export enum ResearchHisotryLogOperationType {
	CHANGE_STATUS,
	REMINDER,
	GROUP_FINISHED,
	QUESTIONNAIRES_SEND,
}

export interface ResearchHistoryLog {
	id: string;
	operationType: ResearchHisotryLogOperationType;
	admin: Admin;
	createdAt: string;
	data: {
		status: ResearchStatus;
		amount: number;
		messageType: NotificationMessageType;
		sendDate: string;
		groupName: string;
		groupId: string;
	};
}

export interface ResearchHistory {
	researchHistory: ResearchHistoryLog[];
}

export enum AssignPointsPanelistStatus {
	ALL,
	COMPLETED,
	QUOTAFULL,
	SCREENOUT,
	INVITED,
}

export type ViewResearchAllowedActions = {
	editLoi: boolean;
	editPoints: boolean;
	sendQuestionnaires: boolean;
	excludePanelist: boolean;
	rewardPanelist: boolean;
	sendNotifications: boolean;
	extendResearch: boolean;
};
