import { QueryFunctionContext } from '@tanstack/react-query';
import { Dictionary, groupBy, pick } from 'lodash';
import { QueryKey } from '@/configs/queryKeys';
import { Gender, HydraResponse } from '@/types/types';
import { AGE_SPANS } from '@/utils/researchUtils';
import { getRegionType } from '@/utils/utilities';
import httpClient from './clients/httpClient';
import hash from 'object-hash';

export enum RegionType {
	PROVINCE,
	COUNTY,
	CITY,
}

export interface Region {
	regionId: string;
	province: string | null;
	county: string | null;
	city: string | null;
}

export interface RegionData extends Region {
	id: string;
	gender: Gender;
	ageFrom: number;
	ageTo: number;
	value: number;
	regionType: RegionType;
}

export interface RegionAgeSpanStats {
	ageFrom: number;
	ageTo: number;
	all: number;
	male: number;
	female: number;
}

export interface GusRegionStats {
	[regionId: string]: RegionAgeSpanStats[];
}

export const CELLLS_PER_REGION = 12;
export const REGIONS_PER_PAGE = 20;

abstract class RegionsApi {
	static get = async ({
		queryKey,
	}: QueryFunctionContext<[string, RegionType, number]>): Promise<{
		total: number;
		data: Dictionary<RegionData[]>;
	}> => {
		const response = await httpClient.get<HydraResponse<RegionData>>(
			queryKey[0],
			{
				headers: { Accept: 'application/ld+json' },
				params: {
					regionType: queryKey[1],
					itemsPerPage: CELLLS_PER_REGION * REGIONS_PER_PAGE,
					page: queryKey[2],
				},
			}
		);

		return {
			data: groupBy(response.data['hydra:member'], 'regionId'),
			total: response.data['hydra:totalItems'],
		};
	};

	static createRegion = async (region: Region) => {
		const regionType = getRegionType(region);

		const regionsData = [
			...AGE_SPANS.map((span) => ({
				...region,
				ageFrom: span[0],
				ageTo: span[1],
				gender: Gender.MALE,
				regionType,
				value: 0,
			})),
			...AGE_SPANS.map((span) => ({
				...region,
				ageFrom: span[0],
				ageTo: span[1],
				gender: Gender.FEMALE,
				regionType,
				value: 0,
			})),
		];

		return await Promise.all(
			regionsData.map((rd) => httpClient.post<RegionData>(QueryKey.regions, rd))
		);
	};

	static update = async (
		region: Pick<RegionData, 'id' | 'value' | 'regionId'>
	) => {
		await httpClient.patch(`${QueryKey.regions}/${region.id}`, {
			value: region.value,
		});
	};

	static delete = async (regionsData: RegionData[]) => {
		await Promise.all(
			regionsData.map((reg) =>
				httpClient.delete(`${QueryKey.regions}/${reg.id}`)
			)
		);
	};

	static syncRegionIds = async () => {
		const response = await httpClient.get<RegionData[]>(QueryKey.regions, {
			params: {
				pagination: false,
			},
		});

		await Promise.all(
			response.data.map((region) => {
				const regionId = hash(
					{
						province: region.province || null,
						county: region.county || null,
						city: region.city || null,
					},
					{
						algorithm: 'md5',
					}
				);

				return httpClient.patch(`${QueryKey.regions}/${region.id}/regionId`, {
					regionId,
				});
			})
		);
	};
}
export default RegionsApi;
