import React, { useContext, ReactNode } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { Language } from '@/types/types';
import APP_CONFIG from '@/configs/appConfig';

interface Context {
	language: Language;
	setLanguage: (lang: Language) => void;
}

const ContentContext = React.createContext<Context>(null!);

export const useLanguage = () => useContext(ContentContext);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
	const [language, setLanguage] = useLocalStorage<Language>({
		key: 'language',
		defaultValue: APP_CONFIG.DEFAULT_LANGUAGE,
		getInitialValueInEffect: false,
	});

	return (
		<ContentContext.Provider
			value={{
				language,
				setLanguage,
			}}
		>
			{children}
		</ContentContext.Provider>
	);
};
